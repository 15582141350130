import React from 'react';
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import BackToSolutionsButton from "../../components/shared/ButtonBackTo/BackToSolutionsButton";
import DcodeMarkerHammer from "../../components/sections/DcodeMarkerHammer/DcodeMarkerHammer";
import DcodeMarkerHammer2 from "../../components/sections/DcodeMarkerHammer2/DcodeMarkerHammer2";
import {getLink, getRoutes} from "../../components/shared/routes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().TechnologyDMH])}/>);


// const Dmh = () => {
//     const routes = getRoutes();
//     const parentTitle = routes.Solutions.pageTitle;
//
//     return (
//         <Layout>
//             <SEO title={parentTitle}/>
//
//             <Breadcrumbs
//                 title={routes.SolutionsDMH.pageTitle}
//                 parentTitles={[parentTitle]}
//                 parentRoutes={[routes.Solutions]}
//             />
//
//             <DcodeMarkerHammer/>
//
//             <DcodeMarkerHammer2/>
//
//             <BackToSolutionsButton blackBg/>
//
//             <AdditionalInformation/>
//
//             <Footer/>
//         </Layout>
//     );
// };
//
// export default Dmh;
